<template>
  <div>
    <UserFeedback :target="t('componentNames.studentExamsList')" class="end mr-4 mt-1"></UserFeedback>
    <b-field>
      <b-button icon-left="refresh" @click="getUserExams" size="is-small">{{t('common.refresh')}}</b-button>
    </b-field>
    <b-table narrowed striped hoverable :data="exams">
      <b-table-column
        field="classroomName"
        :label="t('classroom.header')"
        searchable
        v-slot="props"
      >{{ props.row.classroomName }}</b-table-column>
      <b-table-column
        field="teacherName"
        :label="t('classroom.teacher')"
        v-slot="props"
      >{{ props.row.teacherName }}</b-table-column>
      <b-table-column field="studentName" :label="t('common.student')" v-slot="props">{{ props.row.studentName }}</b-table-column>
      <b-table-column field="title" :label="t('exam.header')" v-slot="props">{{ props.row.title }}</b-table-column>
      <b-table-column field="grade" :label="t('exam.grade')" v-slot="props">
        <p v-if="props.row.isGraded">{{ props.row.grade }}</p>
      </b-table-column>
      <b-table-column field="option" v-slot="props" :label="t('common.options')">
        <b-tooltip :label="t('grade.preview')" v-if="props.row.isGraded">
          <b-button @click="showExam(props.row.id)" icon-left="star" size="is-small"></b-button>
        </b-tooltip>
        <b-tooltip :label="t('exam.stopAndSend')">
          <b-button
            v-if="!props.row.isFinished"
            type="is-danger"
            icon-left="close"
            size="is-small"
            @click="closeExam(props.row.id)"
          ></b-button>
        </b-tooltip>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
    </b-table>
    <b-modal v-model="isInfoActive" v-if="examToShow" :can-cancel="false">
      <template #default="props">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ examToShow.title }}</p>
          <button type="button" class="delete is-primary" @click="props.close" />
        </header>
        <section class="modal-card-body">
          <div class="columns m-4">
            <div class="column is-half" v-if="examToShow.gates">
              <p class="subtitle">{{t('exam.grades')}}</p>
              <div v-for="(grade, index) in examToShow.gates" :key="index">
                <p v-if="index < examToShow.gates.length - 1">
                  {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}}
                  {{ examToShow.gates[index + 1].requiredPoints }}% - {{t('exam.gradeLower')}}
                  {{ grade.grade }}
                </p>
                <p v-else>
                  {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}} 100% - {{t('exam.gradeLower')}}
                  {{ grade.grade }}
                </p>
              </div>
            </div>
            <div class="column is-half">
              <p>
                {{t('grade.infoPoint')}}:
                {{ quizPointQuestions + examToShow.points }}/
                {{ maxPointQuestions + examToShow.maxPoints }}
              </p>
              <p>{{t('grade.infoPercent')}}: {{ percentGrade }}</p>
              <p class="subtitle">{{t('exam.grade')}}: {{ examToShow.grade }}</p>
            </div>
          </div>
        </section>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";
import UserFeedback from "@/components/feedbacks/UserFeedback";

export default {
  name: "UserExamList",
  mixins: [CommonHelpers],
  components: {
    UserFeedback,
  },

  props: {},

  data: function () {
    return {
      exams: [],
      classrooms: [],
      isInfoActive: false,
      examToShow: null,
    };
  },

  mounted() {
    this.getUserExams();
  },

  watch: {},

  computed: {
    maxPointQuestions() {
      let points = 0;
      if (this.examToShow) {
        this.examToShow.questions.forEach((question) => {
          points += question.maxPoints;
        });
      }
      return points;
    },

    quizPointQuestions() {
      let points = 0;
      if (this.examToShow) {
        this.examToShow.questions.forEach((question) => {
          points += question.points;
        });
      }
      return points;
    },

    percentGrade() {
      if (this.examToShow) {
        if (this.maxPointQuestions + this.examToShow.maxPoints > 0) {
          return Math.ceil(
            ((this.quizPointQuestions + this.examToShow.points) /
              (this.maxPointQuestions + this.examToShow.maxPoints)) *
            100
          );
        } else {
          return 0;
        }
      } else return 0;
    },
  },

  methods: {
    showExam(id) {
      this.examToShow = this.exams.find((ex) => ex.id == id);
      this.isInfoActive = !this.isInfoActive;
    },

    closeExam(id) {
      let examToSend = this.exams.find((ex) => ex.id === id);
      if (examToSend) {
        this.updateExam(examToSend);
      } else {
        this.mDangerSnack(this.t('validation.error'));
      }
    },

    updateExam(examToUpdate) {
      examToUpdate.isFinished = true;
      this.$store
        .dispatch(Action.USEREXAM_UPDATE, examToUpdate)
        .then(() => {
          this.mSuccessSnack(this.t('exam.sended'));
          this.getUserExams();
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          //this.isLoading = false;
        });
    },

    getUserExams() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_MY_EXAMS)
        .then((payload) => {
          this.exams = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
div.end {
  float: right;
}
.delete {
  background-color: #f03a5f;
}
</style>
