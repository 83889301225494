<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
      <b-tab-item :label="t('componentNames.exams')" icon="school">
        <UserExamList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import UserExamList from "./UserExamList";

export default {
  name: "StudentsExams",
  mixins: [CommonHelpers],
  components: {
    UserExamList,
  },

  props: {},

  data: function () {
    return {
    };
  },

  mounted() { },

  watch: {},

  computed: {},

  methods: {
  },
};
</script>

<style scoped>
.card {
  overflow: visible !important;
}
</style>
