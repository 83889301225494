<template>
  <div class="home">
    <StudentsExams />
  </div>
</template>

<script>
// @ is an alias to /src
import StudentsExams from '@/components/userexam/StudentsExams'

export default {
  name: 'ExamsStudentView',
  components: {
    StudentsExams
  }
}
</script>